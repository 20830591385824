import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { InlineAgent } from './inlineAgent/InlineAgent';

export const App = () => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    // hide overflow on body when magic search is open
    if (isOpen) {
      document.body.style.maxHeight = '100dvh';
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.position = '';
      document.body.style.maxHeight = '';
      document.body.style.overflow = '';
    }
  }, [isOpen]);

  return (
    <>
      <Trigger setIsMagicSearchOpen={setOpen} isMagicSearchOpen={isOpen} />
      {isOpen &&
        ReactDOM.createPortal(
          <div className="fixed inset-0 z-[999] flex h-full w-dvw overflow-hidden bg-typography-black/50 p-6">
            <InlineAgent popup={true} isOpen={isOpen} setOpen={setOpen} />
          </div>,
          document.body
        )}
    </>
  );
};

const Trigger = ({
  isMagicSearchOpen,
  setIsMagicSearchOpen,
}: {
  isMagicSearchOpen: boolean;
  setIsMagicSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <button
      type="button"
      className="button-fill tab-focus-white group hover:border-primary-accent"
      onClick={() => setIsMagicSearchOpen(!isMagicSearchOpen)}>
      <img
        src="/assets/images/eloquent-icon.svg"
        className="object-cover"
        alt="Vraag het aan AI"
      />
      <span className="relative underline decoration-transparent underline-offset-4 transition-colors duration-200 group-hover:decoration-secondary">
        Vraag <span className="hidden md:inline">het aan</span> AI
      </span>
    </button>
  );
};
