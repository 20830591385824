import typography from '@tailwindcss/typography';

/** @type {import('tailwindcss').Config} */
export default {
  content: ["./site/**/*.php", "./**/*.{ts,tsx}"],
  theme: {
    transitionDuration: {
      DEFAULT: '200ms',
      75: '75ms',
      100: '100ms',
      150: '150ms',
      200: '200ms',
      300: '300ms',
      500: '500ms',
      700: '700ms',
      1000: '1000ms',
    },
    borderRadius: {
      'sm': '4px',
      'none': '0',
      DEFAULT: '0.25rem',
      'md': '0.375rem',
      'lg': '0.5rem',
      'full': '9999px',
    },
    extend: {
      screens: {
        'xs': '321px', // REQUIRED FOR WCAG 1.4.10 
      },
      fontFamily: { 
        "nunito": ["Nunito Sans", "sans-serif"],
        "RO": "RO Serif"
      },
      colors: {
        primary: {
          DEFAULT: "hsl(var(--primary))",
          background: "hsl(var(--primary-background))",
          hover: "hsl(var(--primary-hover))",
          accent: {
            DEFAULT: "hsl(var(--primary-accent))",
            hover: "hsl(var(--primary-accent-hover))",
          } 
        },
        secondary: {
          DEFAULT: "hsl(var(--secondary))",
          background: "hsl(var(--secondary-background))",
          hover: "hsl(var(--secondary-hover))",
          accent: {
            DEFAULT: "hsl(var(--secondary-accent))",
            // hover: "hsl(var(--secondary-accent-hover))",
          }
        },
        typography: {
          black: "hsl(var(--typography-black))",
          gray: "hsl(var(--typography-gray))",
          white: "hsl(var(--typography-white))",
        },
        muted: "hsl(var(--muted))",
        stroke: "hsl(var(--stroke))",
        link: "hsl(var(--link))",
        warning: "hsl(var(--warning))",
      },
      typography: {
        DEFAULT: {
          css: {
            maxWidth: 'none', 
            a : {
              color: 'hsl(var(--link))',
              "font-weight": "700",
            },
            p: {
              color: 'hsl(var(--typography-gray))',
            },
            ul: {
              padding: '0 1.5rem'
            },
          },         
        }
      },
    },
    container: {
      center: true,
      padding: {
        DEFAULT: "1.5rem",
      },
      screens: {
        "2xl": "1312px",
      },
    },
  },
  plugins: [typography],
}

