// Helper functions
function toggleClass(element: HTMLElement, classes: string[]) {
  classes.forEach((cls) => element.classList.toggle(cls));
}

function toggleDataAttribute(element: HTMLElement, attr: string) {
  const isOpen = element.getAttribute(attr) == 'true';
  element.setAttribute(attr, (!isOpen).toString());
  return !isOpen;
}

function setupClickAndTab(element: HTMLElement, callback: () => void) {
  element.addEventListener('click', callback);
  element.addEventListener('keydown', (e) => {
    if (e.key === 'Tab') {
      callback();
    }
  });
}

// Main mobile Menu with tab and click support
const menuTrigger = document.getElementById('menu-trigger') as HTMLElement;
const menuElement = document.getElementById('menu') as HTMLElement;
const navbar = (document.getElementById('navbar-home') ||
  document.getElementById('navbar')) as HTMLElement;

let currentVisibleItem: HTMLElement | null = null;

if (menuTrigger && menuElement) {
  menuTrigger.addEventListener('click', toggleMenu);

  function toggleMenu() {
    toggleDataAttribute(menuElement, 'data-open');
    toggleDataAttribute(navbar, 'data-open');
    toggleDataAttribute(menuTrigger, 'aria-expanded');
    toggleClass(document.body, ['h-dvh', 'overflow-hidden']);
  }

  menuTrigger.addEventListener('keydown', function (event) {
    if (event.key === 'Tab') {
      toggleMenu();
    }
  });
  // Close the menu when tabbing outside
  menuElement.addEventListener('focusout', function (event) {
    if (!menuElement.contains(event.relatedTarget as Node)) {
      toggleMenu();
    }
  });
}

const submenus = document.querySelectorAll<HTMLElement>('.submenu-trigger');

submenus.forEach((submenuTrigger) => {
  submenuTrigger.addEventListener('click', () =>
    toggleDataAttribute(submenuTrigger, 'data-open')
  );
  submenuTrigger.addEventListener('keydown', (e) => {
    if (e.key === 'Tab') {
      if (currentVisibleItem && currentVisibleItem !== submenuTrigger) {
        // Close the previously opened
        currentVisibleItem.setAttribute('data-open', 'false');
      }
      submenuTrigger.setAttribute('data-open', 'true');
      currentVisibleItem = submenuTrigger;
    }
  });
});

const tabTriggers = document.querySelectorAll<HTMLElement>('.tab-triggers');

tabTriggers.forEach((tabTrigger) => {
  tabTrigger.addEventListener('keydown', function (event) {
    if (event.key === 'Tab') {
      const item = this.querySelector('.tab-content') as HTMLElement | null;
      if (item) {
        item.setAttribute('data-open', 'true');
        currentVisibleItem = item;
        item.addEventListener('focusout', function (event) {
          // Close the tab content if the focus is outside the tab content
          if (!item.contains(event.relatedTarget as Node)) {
            item.setAttribute('data-open', 'false');
          }
        });
      }
    }
    // Close using escape key
    if (event.key === 'Escape') {
      currentVisibleItem?.setAttribute('data-open', 'false');
    }
  });
});

// navbar-home only exists on homepage
if (document.getElementById('navbar-home')) {
  document.addEventListener('scroll', function () {
    if (navbar) {
      if (window.scrollY > 100) {
        navbar.setAttribute('data-open', 'true');
      } else {
        navbar.setAttribute('data-open', 'false');
      }
    }
  });
}
